export const projects = [
    {
      title: "Image Sharing App",
      subtitle: "Personal Project",
      description:
        "A photo-sharing app made with Node.js. MongoDB and React.js. \n Developed API documentation using Swagger UI and SwaggerJsDocs",
      image: "https://th.bing.com/th/id/R.660c8334008afef8b87b8697c3bb8235?rik=C4wUwj%2beyQjT9w&pid=ImgRaw&r=0",
      link: "https://github.com/fearalert/",
    },
    {
      title: "Personal Portfolio",
      subtitle: "Personal Project",
      description:
        "This project is a personal portfolio website made using React.js, tailwindCSS and JavaScript.",
      image: "https://th.bing.com/th/id/OIP.8jN0KAPEam88i3ntYEY7DwHaE5?rs=1&pid=ImgDetMain",
      link: "https://github.com/fearalert/personal_website",
    },
    {
      title: "Rubik's Cube 3D Visualization",
      subtitle: "Computer Graphics",
      description:
        "This project is the implementation of 3D computer graphics properties using openGL library in C++.",
      image: "https://purepng.com/public/uploads/large/purepng.com-rubiks-cube3-d-combination-puzzlerubikcubeclassicclipartpuzzle-1421526591425jgi2x.png",
      link: "https://github.com/fearalert/computer-graphics",
    },
    {
      title: "Travelway",
      subtitle: "Semester Project",
      description:
        " A travel guide android application for Nepal with integrated language translation using Google API.",
      image: "https://th.bing.com/th/id/OIP.WIDARL3wURtF_ojJD2Jn2gHaEo?rs=1&pid=ImgDetMain",
      link: "https://github.com/fearalert/TravelWay",
    },
    {
      title: "Medizine",
      subtitle: "Semester Project",
      description:
      "A doctor appointment Android application made using Flutter and Node.js with HL7 FHIR standards.",
      image: "https://th.bing.com/th/id/OIP.fGkzKrChmflU2_QlgRY60gHaE8?rs=1&pid=ImgDetMain",
      link: "https://github.com/fearalert/Medizine",
    },
    {
      title: "Home Utility Application",
      subtitle: "Semester Project",
      description:
      "An android application to bridge the gap between normal people anddaily life technicians. E.g. Plumber, Mechanic, etc.",
      image: "https://handymantips.org/wp-content/uploads/2020/03/Getting-The-Utilities-Set-Up-For-Your-New-Home-In-Houston-600x571.jpg",
      link: "https://github.com/fearalert/Home-Utility",
    },
    {
      title: "KU Loader",
      subtitle: "Semester Project",
      description:
      "A download manager for linux based systems using aria2 library, c++, and Qt.",
      image: "https://th.bing.com/th/id/OIP.Zo4Hxhf3E1qgSdGzuHzoTAHaHa?rs=1&pid=ImgDetMain",
      link: "https://github.com/fearalert/KULOADER-",
    },

    {
      title: "Travelway",
      subtitle: "UI/UX Design",
      description:
        " A travel guide android application for Nepal with integrated language translation using Google API.",
      image: "https://th.bing.com/th/id/OIP.Eit6eCZtAjyVvorBeQpt_QHaFj?w=232&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
      link: "https://www.behance.net/gallery/192838163/Travelway",
    },
    {
      title: "KUCC CUP 2023",
      subtitle: "Poster Designs",
      description:
      "KUCC CUP 2023 posters, banners and certificates designs. Done as graphics designer for KUCC in 2023.",
      image: "https://th.bing.com/th/id/OIP.-4Ium6x51cbN5B1ca7J1egHaGB?w=197&h=180&c=7&r=0&o=5&dpr=1.3&pid=1.7",
      link: "https://www.behance.net/gallery/192846515/Poster-Design-KUCC-CUP-2023-(Kathmandu-University)",
    },
    {
      title: "E-prescribe",
      subtitle: "UI/UX Design",
      description:
      "This is an UI design for the E-prescribe android/IOS application. It includes registration and login screens.",
      image: "https://th.bing.com/th/id/OIP.-aX8wZ6edVZpFPJ7zIH9_QAAAA?rs=1&pid=ImgDetMain",
      link: "https://www.behance.net/gallery/192834963/E-Prescribe-APP-MOCKUP",
    },
    {
      title: "Medizine",
      subtitle: "UI/UX Design",
      description:
      "This is an UI design for the online doctor appointment booking system. This application helps to manage and schedule the appointments.",
      image: "https://th.bing.com/th/id/OIP.kJlaY9pvFOaLfTzZbGbX9AHaD4?rs=1&pid=ImgDetMain",
      link: "https://www.behance.net/gallery/192836151/Medizine-Online-Doctor-Appointment-App",
    },
  ];

export const skills = [
 'HTML, CSS', 'UI/UX Design', 'Javascript', 'Typescript', 'MERN', 'Tailwind, Bootstrap',
 'Next.js', 'web3.js, Ether.js', 'Hyperledger Fabric', 'Solidity', 'SQL, MongoDB' , 'Documentation', 'Docker Basics', 'Python', 'Power BI', 
 'Git', 'Figma, Canva', 'Adobe Illustrator', 'SPSS Data Analysis', 'Flutter & Firebase', 'Teamwork, Leadership', 'Problem-Solving', 'Presentation'
];

export const interests = [
  'Research', 'Blockchain', 'UI/UX Design', 'Information Security', 'Software Engineering', 'Web3', 'MERN Stack', 'Analysis'
];